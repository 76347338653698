.footerLink {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
}
.footerLink:hover {
  color: #01bf71;
  transition: 0.3s ease-out;
}

.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.modal svg {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0 ,0 ,0 , 0.4 );
    color: #fff;
    cursor: pointer;
} 

.show {
    opacity: 1;
}

.hide {
    opacity: 0;
}

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 1170px;
    height: auto;
    background: #f5f2ed;
    margin: 10px auto;
    word-break: break-all;
    border: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .bloc-tabs {
    display: flex;
  }
  .tabs {
    padding: 15px;
    text-align: center;
    width: 50%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
  }
  .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .active-tabs  {
    background: white;
    border-bottom: 1px solid transparent;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: #cd853f;
  }
  button {
    border: none;
    font-size: 1.5em;
    text-align: left !important;  
  }
  .content-tabs {
    flex-grow : 1;
  }
  .content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .content h2 {
    padding: 0px 0 5px 0px;
    font-family: 'Yellowtail';
  }
  .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .content p {
    width: 100%;
    height: 100%;
    text-transform: lowercase;
    font-size: 1em;
  }
  .active-content {
    display: block;
  }

  .video-clip {
    width: 488px !important;
  }

  @media screen and (max-width: 1024px) {
    .container {
        width: 96%;
    }
    .tabs {
        flex-direction: row;
    }
    button {
        font-size: .8em;
    }
    p {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 480px) {
  button span {
    float: left;
  }
  .tabs {
    padding: 10px;
  }
  .video-clip {
    width: 390px !important;
    margin-bottom: 10px;
  }
}